<template>
  <LayoutBox :loading="loading" title="大堂人数变化情况">
    <DatePicker
      slot="layoutBox-right"
      v-model="queryParam.currentDate"
      box-shadow
      :allow-clear="false"
      custom
    ></DatePicker>
    <div ref="LineRef" style="height: 100%"></div>
  </LayoutBox>
</template>

<script>
import moment from 'moment';
import {mapState} from 'vuex';
import LayoutBox from '@/views/screen/components/LayoutBox.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
import {toNumber} from '@/views/screen/config';

export default {
  //组件注册
  components: {LayoutBox, DatePicker},
  //组件传值
  props: {
    globalQuery: {
      type: Object,
      default: () => ({}),
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      queryParam: {},
      lineChart: null,
    };
  },
  //计算属性
  computed: {
    ...mapState({
      yellowWarningNumber: (state) =>
        state.screenStore.analysisitem['yellowWarningNumber'],
      redWarningNumber: (state) =>
        state.screenStore.analysisitem['redWarningNumber'],
    }),
    getOption() {
      return (data) => {
        return {
          grid: {
            top: 30,
            right: 20,
            bottom: 30,
            left: 30,
          },
          visualMap: {
            itemWidth: 0,
            showLabel: false,
            pieces: [
              {
                gte: toNumber(this.yellowWarningNumber),
                lt: toNumber(this.redWarningNumber),
                color: '#ffcc1b',
              },
              {
                gte: toNumber(this.redWarningNumber),
                color: '#fe1329',
              },
            ],
            outOfRange: {
              color: '#25e3ff',
            },
          },
          tooltip: {
            trigger: 'axis',
          },
          yAxis: {
            axisLabel: {
              color: '#DEF8FF',
            },
            minInterval: 1,
            splitLine: {
              show: true,
              lineStyle: {
                type: [4, 5],
                color: '#20579B',
              },
            },
          },
          xAxis: {
            axisLine: {
              lineStyle: {
                color: '#DEF8FF',
              },
            },
            axisTick: {show: false},
            axisLabel: {
              color: '#DEF8FF',
            },
            // splitArea: {
            //   interval: 45
            // },
            data: data.map((e) => e.text),
          },
          series: {
            type: 'line',
            showSymbol: false,
            data: data.map((e) => e.count),
          },
        };
      };
    },
  },
  //属性监听
  watch: {
    queryParam: {
      deep: true,
      handler: 'getData',
    },
    globalQuery: {
      deep: true,
      handler: 'getData',
    },
    refreshKey: {
      handler: 'getData',
    },
  },
  //DOM访问
  mounted() {
    this.lineChart = this.$echarts.init(this.$refs.LineRef);
    this.queryParam = {
      currentDate: moment().format('YYYY-MM-DD'),
    };
  },
  //保存方法
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/hall/change-stat`,
          {
            ...this.globalQuery,
            ...this.queryParam,
          }
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        this.lineChart.setOption(this.getOption(data.data));
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
